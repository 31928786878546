:root {
  --primary: #0e4373 !important;
  --secondary: #3e4954;
  --primary-hover: #1e38bb;
  --primary-dark: #101e63;
  --rgba-primary-1: rgba(47, 163, 221, 0.265);
  --rgba-primary-2: rgba(47, 76, 221, 0.2);
  --rgba-primary-3: rgba(47, 76, 221, 0.3);
  --rgba-primary-4: rgba(47, 76, 221, 0.4);
  --rgba-primary-5: rgba(47, 76, 221, 0.5);
  --rgba-primary-6: rgba(47, 76, 221, 0.6);
  --rgba-primary-7: rgba(47, 76, 221, 0.7);
  --rgba-primary-8: rgba(47, 76, 221, 0.8);
  --rgba-primary-9: rgba(47, 76, 221, 0.9);
}

.text-primary {
  color: #0e4373 !important;
}

.login-form-bx .box-skew1:after {
  background: linear-gradient(155.21deg,
      #358ac9 13.16%,
      #0d4984 58.83%,
      #0e4372 71.3%,
      #113549 93.59%);
}

.nav-header .brand-logo {
  padding-left: 0;
  justify-content: center;
}

.bTn {
  background: linear-gradient(110.24deg,
      #c7f1fb -32.22%,
      #b1e4f3 1.92%,
      #a4dcee 30.5%,
      #77c1e7 60.04%,
      #53ace1 87.08%,
      #46a4df 101.57%);

  box-shadow: 0px 0px 15px 3.75px #3f3d560d;
  color: #0e4373;
  font-weight: 600;
  transition: all 0.3s;
}

.bTn:hover {
  transform: scale(0.96);
  color: #0e4373;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
  background-color: rgba(47, 163, 221, 0.265);
}

.rmsc .dropdown-container {
  border: 0.0625rem solid #f0f1f5 !important;
  border-radius: 0.5rem !important;
  padding: 0.3125rem 0.8rem;
  color: black !important;
}

.rmsc .gray {
  color: #6e6e6e !important;
}

.bg-main-theme {
  background: linear-gradient(155.21deg,
      #358ac9 13.16%,
      #0d4984 58.83%,
      #0e4372 71.3%,
      #113549 93.59%);
}

.category-card {
  transition: all 0.3s;
}

.category-card:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.sub-category-list {
  display: flex;
  align-items: center;
  gap: 10px;
  background: linear-gradient(155.21deg,
      #358ac9 13.16%,
      #0d4984 58.83%,
      #0e4372 71.3%,
      #113549 93.59%);
  padding: 10px;
  margin-top: 10px;
  border-radius: 0.5rem;
  transition: all 0.3s;
  cursor: pointer;
}

.sub-category-list:hover {
  transform: scale(1.03, 1.1);
}

.sub-category-list img {
  height: 35px;
}

.sub-category-list p {
  margin: 0;
  color: #fff;
  font-weight: 500;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border: 0.0625rem solid #f0f1f5;
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
}

.selected-options p {
  margin: 0;
  border: 0.0625rem solid #f0f1f5;
  padding: 5px;
  border-radius: 0.5rem;
}

.selected-option {
  position: relative;
  margin-right: 15px;
}

.selected-option div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  position: absolute;
  top: -5px;
  right: -17px;
  height: 25px;
  width: 25px;
  border-radius: 12px;
  border: 1px solid lightgray;
  transition: all 0.3s;
}

.selected-option div:hover {
  transform: scale(1.1);
  color: red;
}