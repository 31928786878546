/* .table-row {
  border-radius: 14px;
  box-shadow: 4px black;
  background-color: #c1ebd635;
  color: black;
} */
.css-1pahdxg-control {
  border: 0 !important;
  box-shadow: none !important;
}

.css-yk16xz-control {
  border: 0 !important;
}
.pac-container {
  z-index: 1100 !important;
}
.date-filter label {
  color: 393838;
  margin-left: 5px;
  margin-bottom: 0;
}
.search-input {
  border: 1.2px solid #393838;
  /* padding: .2em .2em; */
  border-radius: 1.5em;
}
.date-filter input {
  border: 1px solid #f0f1f5;
  padding: 0.5em 0.5em;
  height: 3.4rem;
  border-radius: 10px;
  color: #393838;
  font-family: pappins;
}
.ad-view {
  box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 12px; /* background-color: aqua; */
  border-radius: 15px 5px 5px 15px;
  padding: 10px;
}

.cover-img {
  display: flex;
  justify-items: center;
  align-items: center;
  padding-left: 3px;
}
.text-left {
  padding: 12px;
}
.link-img img {
  height: 50px;
  width: 50px;
}
.profile-img {
  border-radius: 10px;
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dropdown-menus.show {
  inset: auto auto -10px -45px !important;
  padding: 0.2rem 0 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.view-imgBtn {
  position: absolute;
  top: 90px;
  right: 10px;
  height: 40px;
  width: 40px;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: rgb(227, 247, 235);
}

.Modal {
  background-color: red !important;
  cursor: pointer;
  margin: 10px;
  border-radius: 20px !important;
  height: 80px !important;
}

.answer-btn {
  border: none;
  margin-left: 20px !important;
  background-color: transparent;
  font-size: 1.1rem;
  cursor: pointer;
}

.questn-list {
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;

  margin: 0;
}

.dragging {
  background-color: #c1ebd635 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-evenly !important;
  border: none;
}
.auto-address.css-1wy0on6 {
  display: none !important;
}
.css-1wy0on6 {
  display: none !important;
}

.css-13cymwt-control {
  display: block !important;
  width: 100% !important;
  /* height: calc(2.6em + 0.80rem + 0px) !important;  */

  padding: 0.375rem 0.75rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  /* border: 1px solid #f0f1f5 !important; */
  border-radius: 0.75rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.css-t3ipsp-control:hover {
  border: 1px solid #f0f1f5 !important;
}
.css-13cymwt-control:hover {
  border: 1px solid #f0f1f5 !important;
}
.css-b62m3t-container:hover {
  border: none !important;
}

/* .dropdown-input {
  position: relative;
}

.dropdown-input input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
} */

/* .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
} */

/* .dropdown input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
}

.dropdown button {
  padding: 10px;
  cursor: pointer;
} */
